
import { CourseCollection } from "@planetadeleste/vue-mc-learning";
import { Component, Mixins, Prop } from "vue-property-decorator";
import GlobalMixin from "@/mixins/GlobalMixin";
import CourseCard from "@/modules/courses/components/CourseCard.vue";
import CourseListItem from "@/modules/courses/components/CourseListItem.vue";
import { EventBus } from "@/services/event-bus";
import { AuthModule } from "@/store/auth";

@Component({
  components: {
    CourseCard,
    CourseListItem,
  },
})
export default class CourseListing extends Mixins(GlobalMixin) {
  obCourseCollection: CourseCollection = new CourseCollection();
  bGrid = true;

  @Prop(Boolean) readonly hideNav!: boolean;
  @Prop(Boolean) readonly hideSummary!: boolean;
  @Prop(Boolean) readonly grid!: boolean;
  @Prop(Boolean) readonly list!: boolean;
  @Prop(Boolean) readonly card!: boolean;
  @Prop(Boolean) readonly byStudent!: boolean;
  @Prop([Number, String]) readonly limit!: number;

  get noNav(): boolean {
    return this.hideNav || this.obCourseCollection.length <= 1;
  }

  get isGrid(): boolean {
    if (this.obCourseCollection.length <= 1 || this.list) {
      return false;
    }

    return !this.card && (this.bGrid || this.grid);
  }

  get cols(): Record<string, any> {
    return {
      xs: 12,
      md: this.isGrid ? 6 : undefined,
      lg: this.isGrid ? 4 : undefined,
    };
  }

  get sComponent(): string {
    return this.isGrid || this.card ? "course-card" : "course-list-item";
  }

  created(): void {
    this.obCourseCollection.on("fetch", () => {
      EventBus.emit("courses-loaded", this.obCourseCollection.models);
    });
  }

  mounted(): void {
    this.load();
  }

  async load(): Promise<void> {
    if (this.byStudent) {
      if (AuthModule.isStudent && AuthModule.courses.length) {
        this.obCourseCollection.clear();
        this.obCourseCollection.add(AuthModule.courses);
      }

      return;
    }

    this.loading();

    if (this.limit) {
      this.obCourseCollection.limit(this.limit);
    }

    this.obCourseCollection.filterBy({ active: 1 });
    await this.obCourseCollection.fetch();
    this.loaded();
  }
}
