
import { Component, Prop, Mixins } from "vue-property-decorator";
import { CourseData } from "@planetadeleste/vue-mc-learning";
import CourseThumb from "@/modules/courses/components/course/CourseThumb.vue";
import CourseDuration from "@/modules/courses/components/course/CourseDuration.vue";
import CourseRating from "@/modules/courses/components/course/CourseRating.vue";
import CourseTitle from "@/modules/courses/components/course/CourseTitle.vue";
import CourseSummary from "@/modules/courses/components/course/CourseSummary.vue";
import CourseBtn from "@/modules/courses/components/course/CourseBtn.vue";
import AuthMixin from "@/mixins/AuthMixin";

@Component({
  components: {
    CourseThumb,
    CourseDuration,
    CourseRating,
    CourseTitle,
    CourseSummary,
    CourseBtn,
  },
})
export default class CourseListItem extends Mixins(AuthMixin) {
  @Prop(Object) readonly item!: CourseData;
  @Prop(Boolean) readonly hideSummary!: boolean;

  get progress(): number | null {
    if (!this.user) {
      return null;
    }

    return this.$_.get(this.item, "student_progress");
  }
}
